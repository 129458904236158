<template>
  <div
    class="sf-input"
    :class="{
      'has-text': !!inputValue,
      invalid: !!inputValue && errorMessage,
    }"
    :data-testid="name"
  >
    <div class="sf-input__wrapper">
      <input
        :id="name"
        :name
        :type
        :value="inputValue"
        :autocomplete
        placeholder=" "
        :required
        :disabled
        v-bind="$attrs"
        @input="handleChange"
        @blur="handleBlur"
      />
      <label class="sf-input__label" :for="name">{{ label }}</label>
      <p v-if="errorMessage" class="sf-input__error-message">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  autocomplete: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const name = toRef(props, 'name')

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value.value,
})

const emit = defineEmits(['update:value'])

watch(inputValue, (newValue) => {
  emit('update:value', newValue)
})
</script>

<style lang="scss" scoped>
.sf-input {
  --c-primary: #333;

  .sf-input__label {
    top: 10px;
    font-size: 16px;
  }

  input:focus ~ .sf-input__label,
  input:not(:placeholder-shown) ~ .sf-input__label,
  input:-webkit-autofill ~ .sf-input__label,
  input:-webkit-autofill:hover ~ .sf-input__label,
  input:-webkit-autofill:focus ~ .sf-input__label {
    top: 0px;
    font-size: 10px;
  }
}
</style>
