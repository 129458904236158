<template>
  <SfButton class="add-to-cart-button" data-testid="product-card-add-to-cart__button" @click="toggleAtcModal"> <PlusIcon /> {{ $t('Quick add to cart') }} </SfButton>
</template>

<script lang="ts" setup>
import PlusIcon from '~/assets/icons/plus.svg'
import { useProduct } from '~/composables'
import { storeToRefs } from 'pinia'
const logger = useAppLogger("AddToCart")

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
  },
})

const { getProductDetails } = useProduct()
const { setProduct } = useProductStore()
const { activeProductSku } = storeToRefs(useProductStore())
const { showAtcModal } = storeToRefs(useCartStore())

const loading = ref(false)

const toggleAtcModal = async () => {
  loading.value = true
  const sku = props.product?.sku
  activeProductSku.value = sku

  showAtcModal.value = true

  // Otherwise, fetch it
  const result = await getProductDetails({
    filter: {
      sku: {
        eq: sku,
      },
    },
  })

  if (!result) {
    return logger.error('Product not found')
  }
  await setProduct(result?.items[0])
  loading.value = false
}
</script>

<style scoped lang="scss">
.add-to-cart-button {
  svg {
    min-width: 20px;
    max-width: 20px;
  }

  @include for-mobile {
    font-size: 0.75rem;

    svg {
      min-width: 16px;
      max-width: 16px;
    }
  }
}
</style>
