<template>
  <div class="product-card-labels">
    <SfBadge v-for="label in labels" :key="label.text" class="product-card__badge" :class="label.class">
      {{ label.text }}
    </SfBadge>
  </div>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
  product: {
    type: Object,
    default: () => ({}),
  },
  isComingSoon: {
    type: Boolean,
    required: true,
  },
  isSoldOut: {
    type: Boolean,
    required: true,
  },
  hidePlpTags: {
    type: String,
    default: '',
  },
})

const showSaleLabel = computed(() => {
  const tags = props.hidePlpTags?.toLowerCase() || ''
  return !tags.includes('sale') && !tags.includes('promotions')
})

const labels = computed(() => {
  const labels = []
  if (props.isComingSoon) {
    labels.push({
      text: t('COMING SOON'),
      class: 'sf-badge--coming-soon',
    })
  } else if (props.isSoldOut) {
    labels.push({
      text: t('BACK SOON'),
      class: 'sf-badge--back-soon',
    })
  } else if (props.product.specialPrice && showSaleLabel.value) {
    labels.push({
      text: t('SALE'),
      class: 'sf-badge--sale',
    })
  }

  if (props.product.labels?.length) {
    labels.push(
      ...props.product.labels?.map((label) => {
        return {
          text: label,
          class: 'sf-badge--regular',
        }
      }),
    )
  }

  return labels?.slice(0, 2)
})
</script>

<style lang="scss" scoped>
.product-card-labels {
  position: absolute;
  top: var(--product-card-badge-top, var(--spacer-xs));
  left: var(--product-card-badge-top, var(--spacer-xs));
  display: flex;
  flex-direction: column;
  gap: var(--spacer-2xs);

  .sf-badge {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 26px;
    padding: 0 var(--spacer-2xs);
    width: max-content;
    background: var(--white-color);
    color: var(--black-color);
    border-radius: 4px;
    box-shadow: 1px 1px 2px 0px #0000001a;
    font-size: var(--font-size-10);
    font-weight: 400;

    @include for-desktop {
      padding: 0 var(--spacer-xs);
      font-size: var(--font-size-13);
      min-height: 34px;
    }

    &--back-soon,
    &--coming-soon {
      background: var(--gray-secondary-color);
      color: var(--white-color);
    }

    &--sale {
      background: var(--black-color);
      color: var(--white-color);
    }

    &--regular {
      text-transform: none;
    }
  }
}
</style>
